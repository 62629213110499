<template>
    <el-form-item
        v-if="eleItem && eleItem.key"
        :label="eleItem.name"
        class="widget-view"
        :class="{ active: selectWidget.key === eleItem.key }"
        :required="eleItem.options.required"
        @click.native.stop="handleSelectWidget()"
    >
        <!-- 单行文本 -->
        <template v-if="eleItem.type === 'input'">
            <el-input
                v-model="eleItem.options.defaultValue"
                :style="{ width: eleItem.options.width }"
                :placeholder="eleItem.options.placeholder"
                :disabled="eleItem.options.disabled"
                :show-password="eleItem.options.password"
                :clearable="eleItem.options.clearable"
                :maxlength="eleItem.options.maxlength"
                show-word-limit
                readonly
            />
        </template>
        <!-- 多行文本 -->
        <template v-if="eleItem.type === 'textarea'">
            <el-input
                v-model="eleItem.options.defaultValue"
                type="textarea"
                readonly
                :style="{ width: eleItem.options.width }"
                :rows="eleItem.options.rows"
                :placeholder="eleItem.options.placeholder"
                :disabled="eleItem.options.disabled"
                :maxlength="eleItem.options.maxlength"
                show-word-limit
            />
        </template>
        <!-- 单选 -->
        <template v-if="eleItem.type === 'radio'">
            <el-radio-group v-model="eleItem.options.defaultValue">
                <el-radio
                    v-for="(item, index) in eleItem.options.valueData"
                    :key="index"
                    :label="item.label"
                    :style="{ display: eleItem.options.inline }"
                    :class="{ radioLineHeight: !eleItem.options.border }"
                    :disabled="eleItem.options.disabled"
                    :border="eleItem.options.border"
                    >{{ item.value }}</el-radio
                >
            </el-radio-group>
        </template>
        <!-- 多选 -->
        <template v-if="eleItem.type === 'checkbox'">
            <el-checkbox-group v-model="eleItem.options.defaultValue" :max="eleItem.options.max">
                <el-checkbox
                    v-for="(item, index) in eleItem.options.valueData"
                    :key="index"
                    :label="item.label"
                    :style="{ display: eleItem.options.inline }"
                    :disabled="eleItem.options.disabled"
                    :border="eleItem.options.border"
                    >{{ item.value }}</el-checkbox
                >
            </el-checkbox-group>
        </template>
        <!-- 计数器 -->
        <template v-if="eleItem.type === 'inputNumber'">
            <el-input-number
                v-model="eleItem.options.defaultValue"
                :min="eleItem.options.min"
                :max="eleItem.options.max"
                :controls-position="eleItem.options.position"
                :disabled="eleItem.options.disabled"
            />
        </template>
        <!-- 下拉框 -->
        <template v-if="eleItem.type === 'select'">
            <el-select
                v-model="eleItem.options.defaultValue"
                placeholder="请选择一个选项"
                :style="{ width: eleItem.options.width }"
                :multiple="eleItem.options.multiple"
                :clearable="eleItem.options.clearable"
                :disabled="eleItem.options.disabled"
            >
                <el-option
                    v-for="item in eleItem.options.valueData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </template>
        <!-- 滑块 -->
        <template v-if="eleItem.type === 'slider'">
            <el-slider
                v-model="eleItem.options.defaultValue"
                :disabled="eleItem.options.disabled"
                :min="eleItem.options.min"
                :max="eleItem.options.max"
            />
        </template>
        <!-- 评分  -->
        <template v-if="eleItem.type === 'rate'">
            <el-rate
                v-model="eleItem.options.defaultValue"
                :max="eleItem.options.max"
                :disabled="eleItem.options.disabled"
                :show-text="eleItem.options.text"
                style="margin-top: 6px"
            />
        </template>
        <!-- 时间选择器 -->
        <template v-if="eleItem.type === 'timePicker'">
            <el-time-picker
                v-model="eleItem.options.defaultValue"
                :style="{ width: eleItem.options.width }"
                placeholder="选择时间"
                :disabled="eleItem.options.disabled"
                :clearable="eleItem.options.clearable"
                :default-value="new Date()"
                value-format="HH:mm:ss"
                format="HH:mm:ss"
            />
        </template>
        <!-- 日期选择器 -->
        <template v-if="eleItem.type === 'datePicker'">
            <el-date-picker
                v-model="eleItem.options.defaultValue"
                placeholder="选择日期"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :value-format="eleItem.options.valueFormat"
                :default-value="new Date()"
                :style="{ width: eleItem.options.width }"
                :type="eleItem.options.type"
                :disabled="eleItem.options.disabled"
                :clearable="eleItem.options.clearable"
            />
        </template>
        <!-- 文字 -->
        <template v-if="eleItem.type === 'text'">
            <div
                :class="{ 'form-text': listdata.config.labelPosition !== 'top' }"
                :style="{
                    display: 'grid',
                    'justify-content': eleItem.options.position,
                    'font-size': eleItem.options.size + 'px',
                    color: eleItem.options.color,
                    'line-height': eleItem.options.lineHeight + 'px',
                    'font-weight': eleItem.options.weight,
                }"
            >
                <div
                    :style="{
                        'padding-left': eleItem.options.leftPadding + 'px',
                        'font-style': eleItem.options.style,
                        'text-indent': eleItem.options.indent + 'em',
                    }"
                >
                    {{ eleItem.options.defaultValue }}
                </div>
            </div>
        </template>
        <!-- 分割线 -->
        <template v-if="eleItem.type === 'divider'">
            <div :class="{ 'form-text': listdata.config.labelPosition !== 'top' }">
                <el-divider :content-position="eleItem.options.position">
                    {{ eleItem.options.defaultValue }}
                </el-divider>
            </div>
        </template>
        <template v-if="eleItem.type == 'file'">
            <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
                <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
                <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
        </template>
        <template v-if="eleItem.type == 'img'">
            <el-upload
                class="upload-demo"
                style="border: 1px dashed #ccc;width: 120px;"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <!-- <el-button size="small" type="primary">点击上传</el-button> -->
            </el-upload>
        </template>

        <!-- 组件操作按钮 -->
        <div class="view-operations">
            <!-- 移动 -->
            <div>
                <el-tag effect="dark" class="movetag">
                    <i class="el-icon-rank" />
                </el-tag>
            </div>
            <!-- 删除 -->
            <div>
                <el-tag effect="dark" @click="removeWidgetElement()"> <i class="el-icon-delete"/></el-tag>
            </div>
        </div>
    </el-form-item>
</template>
<script>
import draggable from "vuedraggable";
import VueCookies from "vue-cookies";
export default {
    components: {
        draggable: draggable,
    },
    props: ["select", "eleItem", "eleIndex", "listdata"],
    data() {
        return {
            selectWidget: this.select,
            header: {
                Token: sessionStorage.getItem("token") || VueCookies.get("token"),
            },
        };
    },
    watch: {
        select(itemdata) {
            this.selectWidget = itemdata;
        },
        selectWidget: {
            handler(data) {
                this.$emit("update:select", data);
            },
        },
    },
    methods: {
        // 点击不同的组件触发
        handleSelectWidget() {
            this.selectWidget = this.eleItem;
        },
        // 删除组件方法
        removeWidgetElement() {
            const index = this.eleIndex;
            if (this.listdata.list.length - 1 === index) {
                if (index === 0) {
                    this.selectWidget = {
                        key: null,
                        options: {
                            placeholder: null,
                        },
                    };
                    console.log(this.eleItem);
                } else {
                    this.selectWidget = this.listdata.list[index - 1];
                }
            } else {
                this.selectWidget = this.listdata.list[index + 1];
            }
            this.$nextTick(() => {
                this.listdata.list.splice(index, 1);
            });
        },
    },
};
</script>
<style scoped>
.view-operations {
    display: none;
}
.widget-view {
    margin-bottom: 0px !important;
}

.active {
    border: 2px solid #409eff !important;
}
.active .view-operations {
    position: absolute;
    bottom: -5px;
    right: 0;
    display: flex;
    cursor: pointer;
}
.grid-template {
    margin-left: -100px !important;
}
.grid-template > .columns {
    border: 2px solid #9999;
    height: 50px;
}
.radioLineHeight {
    line-height: 32px;
}
.form-text {
    margin-left: -100px;
}
.avatar-uploader .el-upload {
    /* border: 1px dashed #d9d9d9; */
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
</style>
