<template>
  <div class="main-form-box">
    <!-- 拖拽为空时显示 -->

    <el-form
      :size="configFormData.config.size"
      :label-position="configFormData.config.labelPosition"
      :label-width="configFormData.config.labelWidth + 'px'"
    >
      <!-- 拖拽展示组件 -->
      <draggable v-if="configFormData.list.length === 0" class="null-form">
        <i class="el-icon-files null-icon" />
        <div>从左侧拖拽组件</div>
      </draggable>
      <draggable
        v-model="configFormData.list"
        v-bind="{ group: 'people', animation: 200 }"
        class="container-form"
        handle=".movetag"
        @add="handleWidgetAdd"
      >
        <!-- 循环展示拖拽的组件 -->
        <div
          v-for="(item, index) in configFormData.list"
          :key="index"
          class="formitem-style"
        >
          <!-- 栅格布局 -->
          <template v-if="item.type === 'grid'">
            <el-row
              v-if="item && item.key"
              type="flex"
              :gutter="item.options.gutter"
              :justify="item.options.justify"
              :align="item.options.align"
              class="widget-col"
              :class="{ active: selectWidget.key === item.key }"
              @click.native="handleSelectWidget(index)"
            >
              <el-col
                v-for="(col, colIndex) in item.columns"
                :key="colIndex"
                :span="col.span ? col.span : 0"
              >
                <draggable
                  v-model="col.list"
                  :no-transition-on-drag="false"
                  v-bind="{
                    group: 'people',
                    animation: 200,
                    handle: '.drag-widget'
                  }"
                  class="widget-col-list"
                  handle=".drag-widget"
                  @add="handleWidgetColAdd($event, item, colIndex)"
                >
                  <template v-for="(el, i) in col.list">
                    <widgetformitem
                      v-if="el && el.key"
                      :select.sync="selectWidget"
                      :ele-item="el"
                      :ele-index="i"
                      :listdata="col"
                    />
                  </template>
                </draggable>
              </el-col>
              <!-- 删除 -->
              <div
                v-if="selectWidget.key === item.key"
                class="widget-col-action"
              >
                <el-tag
                  class="grid-tag"
                  effect="dark"
                  size="mini"
                  @click="removeWidgetElement(index)"
                >
                  <i class="el-icon-delete"
                /></el-tag>
                <el-tag effect="dark" class="grid-tag drag-widget" size="mini">
                  <i class="el-icon-rank" />
                </el-tag>
              </div>
            </el-row>
          </template>
          <template v-else>
            <widgetformitem
              v-if="item && item.key"
              :select.sync="selectWidget"
              :ele-item="item"
              :ele-index="index"
              :listdata="configFormData"
            />
          </template>
        </div>
      </draggable>
    </el-form>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
// 每个表单数据
import widgetformitem from './widgetFormItem'
import { copy as util_Copy } from '@/utils/clone'
export default {
  components: {
    draggable: draggable,
    widgetformitem: widgetformitem
  },
  // 接收拖拽时传递过来的数据
  props: ['configFormData', 'select'],
  data() {
    return {
      // 拖拽的表单
      selectWidget: this.select
    }
  },
  watch: {
    select(itemdata) {
      this.selectWidget = itemdata
    },
    selectWidget: {
      handler(data) {
        this.$emit('update:select', data)
      }
    }
  },
  methods: {
    // 拖拽完成事件
    handleWidgetAdd(e) {
      // 获取当前拖拽的元素在放置栏中的下标
      const newIndex = e.newIndex
      // 设置元素唯一key值
      const key = `${Date.parse(new Date())}_${Math.ceil(
        Math.random() * 99999
      )}`
      // 拖拽完成后往当前元素的options添加remoteFunc，options平级添加key，model
      this.$set(this.configFormData.list, newIndex, {
        // 解构语法解构当前数据达到深拷贝效果，改变数据不会影响原有数据
        ...this.configFormData.list[newIndex],
        // 同名属性覆盖原有属性
        options: {
          ...this.configFormData.list[newIndex].options,
          remoteFunc: 'func_' + key
        },
        // 新增属性key
        key,
        // 新增属性model
        model: this.configFormData.list[newIndex].type + '_' + key,
        rules: []
      })

      // 深拷贝数据，使重复组件之前相互不受影响
      this.$set(
        this.configFormData.list,
        newIndex,
        util_Copy(this.configFormData.list[newIndex])
      )
      // 获取拖拽的表单数据,子父组件数据双向绑定实时改变右侧配置
      this.selectWidget = this.configFormData.list[newIndex]
    },
    // 点击栅格布局组件触发
    handleSelectWidget(index) {
      this.selectWidget = this.configFormData.list[index]
    },
    // 栅格布局删除组件方法
    removeWidgetElement(index) {
      if (this.configFormData.list.length - 1 === index) {
        if (index === 0) {
          this.selectWidget = {
            key: null,
            options: {
              placeholder: null
            }
          }
          console.log(this.eleItem)
        } else {
          this.selectWidget = this.configFormData.list[index - 1]
        }
      } else {
        this.selectWidget = this.configFormData.list[index + 1]
      }
      this.$nextTick(() => {
        this.configFormData.list.splice(index, 1)
      })
    },
    // 栅格布局拖拽完成事件
    handleWidgetColAdd($event, row, colIndex) {
      const newIndex = $event.newIndex
      const oldIndex = $event.oldIndex
      const item = $event.item
      // 设置元素唯一key值
      const key = `${Date.parse(new Date())}_${Math.ceil(
        Math.random() * 99999
      )}`
      // 拖拽完成后往当前元素的options添加remoteFunc，options平级添加key，model
      this.$set(row.columns[colIndex].list, newIndex, {
        // 解构语法解构当前数据达到深拷贝效果，改变数据不会影响原有数据
        ...row.columns[colIndex].list[newIndex],
        // 同名属性覆盖原有属性
        options: {
          ...row.columns[colIndex].list[newIndex].options,
          remoteFunc: 'func_' + key
        },
        // 新增属性key
        key,
        // 新增属性model
        model: row.columns[colIndex].list[newIndex].type + '_' + key,
        rules: []
      })

      // 深拷贝数据，使重复组件之前相互不受影响
      this.$set(
        row.columns[colIndex].list,
        newIndex,
        util_Copy(row.columns[colIndex].list[newIndex])
      )
      // 获取拖拽的表单数据,子父组件数据双向绑定实时改变右侧配置
      this.selectWidget = row.columns[colIndex].list[newIndex]
    }
  }
}
</script>

<style scoped lang="less">
.main-form-box {
  position: relative;
}
.null-form {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  color: #999999;
}
.null-icon {
  display: grid;
  align-items: end;
  font-size: 93px;
}

.container-form {
  margin: 10px;
  border: 1px dashed #999;
  min-height: 89vh;
  padding-right: 5px;
}
.formitem-style {
  margin: 5px;
  border: 1px dashed #999;
  //   padding: 5px;
}
.formitem-style /deep/ .el-form-item {
  padding: 5px;
}
.view-operations {
  display: none;
}
.widget-view-grid {
  margin-bottom: 0px !important;
  height: 30px;
}
.widget-col {
  padding-bottom: 0;
  padding: 5px;
  background-color: rgba(253, 246, 236, 0.3);
  &.active {
    outline: 2px solid #e6a23c;
  }
  &:hove {
    background: #fdf6ec;
    outline: 1px solid #e6a23c;
    outline-offset: 0px;
  }
  &.active {
    outline: 2px solid #e6a23c;
    border: 1px solid #e6a23c;
    outline-offset: 0;
  }
  .el-col {
    min-height: 50px;
  }
  .widget-col-action {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    cursor: pointer;
  }
  .grid-tag {
    background: #e6a23c;
    border: 1px solid #e6a23c;
  }
}
.widget-col-list {
  min-height: 50px;
  border: 1px dashed #ccc;
  background: #fff;
}
</style>
